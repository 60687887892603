import { type MouseEvent, useMemo } from 'react'

import { useAb } from 'hooks'
import links from 'links'
import { constants, processError } from 'helpers'
import { useDevice } from 'device'

import { useSubscription } from 'modules/subscription'
import { useLogout, useUser, useReferralInvites } from 'modules/user'

import { navMessages as messages } from 'compositions/navigation'
import ReferralNavItem from 'compositions/Header/components/ReferralNavItem/ReferralNavItem'


const useUserNav = () => {
  const { isMobile } = useDevice()
  const logout = useLogout()

  const { hasBillingAddress } = useUser()

  const { subscription } = useSubscription()
  const isSubscribed = subscription?.isSubscribed
  const hasNeverSubscribed = subscription?.hasNeverSubscribed

  // hide upgrade link for users with 3 products per month plan
  const canUpgradeProductsCount = (
    subscription?.stateOptions?.canUpgrade
    && subscription?.plan?.productsPerPeriod < 3
  )

  const { stats: { available } } = useReferralInvites()
  const isReferralItemVisible = Boolean(available)

  return useMemo(() => {
    const data: Navigation.HeaderProfileSection[] = [
      {
        id: 'user-membership',
        title: messages.membership,
        list: [
          {
            id: 'user-membership-queue',
            title: messages.queue,
            to: links.queue,
          },
          {
            id: 'user-membership-orders',
            title: messages.orderHistory,
            to: links.profile.orderHistory,
          },
          !hasNeverSubscribed && {
            id: 'user-membership-subscription',
            title: messages.manageSubscription,
            to: links.profile.subscriptionStatus,
          },
          canUpgradeProductsCount && {
            id: 'user-membership-upgrade',
            title: messages.upgrade,
            to: links.upgradeProduct,
          },
          // TODO handle resubscribe? - added on 07.12.2021 by sonatskiy
          hasNeverSubscribed && {
            id: 'user-membership-subscribe',
            title: messages.subscribe,
            to: links.subscribe,
          },
        ].filter(Boolean),
      },
      {
        id: 'user-account',
        title: messages.account,
        list: [
          hasBillingAddress && {
            id: 'user-account-billing',
            title: messages.billingInfo,
            to: links.profile.billing,
          },
          {
            id: 'user-account-shipping',
            title: messages.shippingInfo,
            to: links.profile.shipping,
          },
          {
            id: 'user-account-personal',
            title: messages.personalInfo,
            to: links.profile.personalInfo,
          },
          isSubscribed && {
            id: 'user-account-reviews',
            title: messages.reviews,
            to: links.profile.reviews,
          },
          isReferralItemVisible && {
            id: 'referral-nav-item',
            to: links.invite,
            title: messages.referralProgram,
            content: (
              <ReferralNavItem
                spanClassName={`text-white align-text-bottom ${isMobile ? 'bg-gold-70' : 'bg-gold-50'}`}
                freeFragranceClassName={isMobile ? 'text-gold-70' : 'text-gold-30'}
              />
            ),
          },
          {
            id: 'user-account-help',
            title: messages.support,
            toTab: 'https://scentbird.zendesk.com/hc/en-us',
          },
          {
            id: 'user-account-logoff',
            title: messages.logoff,
            href: links.logoff,
            onClick: async (event: MouseEvent) => {
              event.preventDefault()
              try {
                await logout()
                window.location.reload()
              }
              catch (error) {
                processError(error)
              }

            },
          },
        ].filter(Boolean),
      },
    ]

    return data.filter(Boolean)
  }, [
    canUpgradeProductsCount,
    hasBillingAddress,
    hasNeverSubscribed,
    isReferralItemVisible,
    isSubscribed,
    logout,
    isMobile,
  ])
}


export default useUserNav
